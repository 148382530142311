footer {
  margin-top: 100px;
}

body {
  overflow-y: auto; 
  -webkit-user-select: none;/* Safari */
  -ms-user-select: none;/* IE 10 and IE 11 */ 
  user-select: none;/* Standard syntax */
  font-family: "Roboto","Helvetica","Arial",sans-serif;
}



:root {
  --tabColor: #000;
  --actionButton: white;
  --lightGrey: #fcfcfc;
  --error: #e3574c;
  --confirmationColor: #A1E043;

  --appBar: #225461;
  --details: #4EC3E0;
  --mainLight: #286272;
  --selectedTabColor: #E4F6FA;
  --lightDetails: #E4F6FA;
  --gridHeader: #FEFDF4;

  --gridBar: #286272ba;
  --decreeTag: #286272ba;
  --lightDetailsSelected: #e4f6fa85;
  --gray: #5f5f5f;
  --yellow: #f0c94a;
  --white: #fff;
  --loginProviders:#002760;
  --resetButton: #808080a8; 
}

.textBold{
  font-weight: bold;
}

.textImportant{
  font-weight: bold;
  color: var(--mainLight);
}

.actionButton{
  color: black !important;
  background-color: var(--actionButton) !important;
}

.confirmationButton{
  color: white !important;
  background-color: var(--confirmationColor) !important;
}

.button {
  background-color: var(--details) !important;
}

.popupCloseButton {
  color: var(--gray);
  cursor: pointer;
}

.popupCloseButton:hover {
  color: var(--error);
  transform: scale(1.1);
}

.tag {
  background-color: var(--details);
  margin: 2px;
  padding: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 5px;
  color: white;
}

.tag.small {
  background-color: var(--secondaryDetails);
  padding: 3px;
  padding-top: 1px;
  padding-bottom: 1px;
}

.bg-green {
  background-color: var(--confirmationColor) !important;
}

.bg-yellow {
  background-color: var(--yellow) !important;
}

.bg-red {
  background-color:var(--error) !important;
}

.green {
  color: #61cc8a !important;
}

.red {
  color: var(--error) !important;
}

.sticky {
  position: fixed !important;
  /*top: 15px !important;*/
  top: 41px !important;
}

#signOut {
  border-radius: 5px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
}

#signOut:hover {
  background-color: #e7e9eb;
}


@media (max-width: 1024px) {
  .sidePanel {
    display: none !important;
  }

  .sidePanel.reportPanel {
    display: inline !important;
    position: static !important;
    width: 100%;
    margin-top: 10px;
  }

  .centerPanel {
    width: 100%;
  }

  .categoryInfoTag {
    background-color: var(--decreeTag);
    margin: 2px;
    padding: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    color: var(--white);
    display: inline-flex;
  }
}

.link {
  cursor: pointer;
  text-decoration-line: underline;
}

.loginProvider {
  padding: 10px !important;
  width: 100% !important;
  justify-content: flex-start !important;
  font-size: 16px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  color: var(--loginProviders) !important;
}

.loginProvider:hover {
  background-color: #e7e9eb !important;
}


.numericFieldArrows {
  margin-left: 3px;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
}

.numericFieldArrows:hover {
  border: 1px solid black
}

.numericFieldArrows .arrow {
  border-radius: 3px;
  background-color: var(--white);
  height: 50%;
  color: #c4c4c4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.numericFieldArrows .arrow:hover {
  background-color: #eaf1f9;
  color: var(--details);
}

.gridFilterBar {
  background-color: var(--white) !important;
  box-shadow: none !important;
  padding: 10px 0px;
  margin-bottom: 10px;
}

.gridActionBar {
  background-color: var(--gridBar) !important;
  padding: 10px;
  margin-bottom: 10px;
}

.pageNameBox{
  margin-bottom: 20px;
  display: table;
}

.pageName{
  padding-left: 9px;
  display: table-cell;
  font-size: 20px;
}

.pageNameIcon{
  width: 14px;
  height: 14px;
  border-radius: 7px;
  display: table;
  vertical-align: middle;
  background-color: var(--details);
  text-align: center;
   margin-left: 3px;
}

.predefinedIcon {
  width: 14px;
  height: 14px;
  line-height: 14px;
  border-radius: 50%;
  display: inline-block;
  color: var(--white);
  vertical-align: middle;
  background-color: var(--details);
  text-align: center;
   margin-left: 3px;
}

.predefinedIconText {
  font-style: normal;
  font-size: 12px;
}

.linkField{
  color: var(--details) !important;
  text-decoration-color: var(--details) !important;
  cursor: pointer;
}


.additionalDataPanelDecree{
  color: var(--details) !important;
}

.selectedAccordion{
  border: 1px solid var(--details) !important ;
}

.categoryInfoTag {
  background-color: var(--decreeTag);
  margin: 2px;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  color: var(--white);
  display: inline-flex;
}

.categoryInfoTag.account {
  background-color: var(--mainLight);
}

.signInMarketingText {
  color: var(--details);
  font-weight: bold !important;
}

.loadKsefInvoiceLabel {
  height: 40px;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  padding: 6px 0px;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

.loadKsefInvoiceLabel:hover {
  border-color: black;
}

.aboutTitle{
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 5px;
}

.aboutSection{
  padding-bottom: 10px;
  font-size: 14px;
}

.resetButton{
  color: white !important;
  background-color: var(--resetButton) !important;
}

.examplesItemsButton{
  background-color: var(--lightDetails) !important;
  margin: 1px !important; 
  color: #000 !important;
  text-align: left !important;
  justify-content: flex-start !important;
}